ul {
  li{
    list-style-type: square ;
    &::marker{
      color: #e71d72;
    }
  }
}


.row-start-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    gap: 1rem;
    margin: 1rem 0;

  @media (max-width: 770px) {
    justify-content: flex-start;
  }
  }

.footer-block{
  background-color: #999;
  color: white;
  padding: 2rem 0;
  margin-top: 1rem ;

  ul li{
    list-style: none;
  }
  a {
    color: white;

    &:active{
      color: #e71d72;
    }
  }
}
main{
  min-height: 90vh;
}

.widget-text, .widget-textarea{
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: center;
  label{
    width: 20%;
  }
  input{
    width: 70%;
    border-radius: 15px;
    border: black 1px solid;
    padding: 0 1rem;

  }
}

.widget-submit{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  button{
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: white;
  background-color: black;
    border: black;
    &:hover{
      cursor: pointer;
      background-color: #999;
      color: black;
      border: #999;
    }

  }
}

ul li a{
  text-decoration: none;
}