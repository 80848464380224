.header-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin: 1rem 0;

  .header-logo{
    img{
      height: 2.5rem;
      width: auto;
    }
  }

  .header-menu{
    color: black;
    width: 50%;
    padding-top: 8px;
    ul.level_1{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      li {
        list-style: none;
        a{
          text-decoration: none;
        }
      }

    }
    @media (max-width: 900px)
    {
      display: none;
    }
  }

}

li.submenu {
  ul.level_2 {
    background-color: #fafafa;
    padding: 0.7rem 0;
    display: none;
    gap: 0.6rem;
    flex-direction: column !important;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    position: absolute;
    li{
      max-width: 18rem;
      padding: 0.2rem 0.5rem;
      a, strong{
        font-size: 0.8rem;
      }
    }

  }
  &:hover{
    ul.level_2{
      display: flex;
      li:not(.active){
        &:hover{
          background-color: #e71d72;
          a{
            color: white;
          }
        }
      }
    }
  }

  &::after {
    content: "▼"; // Unicode character for a down arrow
    font-size: 0.8rem;
    margin-left: 0.1rem;
    color: inherit; // This makes the arrow match the color of the text
  }
}

.footer-nav nav ul {
  display: flex;
  padding: 0;
  flex-direction: row;
  gap: 2rem;
}

.mobile-nav-wrapper{
  display: none;
  .burger-button{
    position: relative;
    img{
      width: 2rem;
      height: auto;

    }
  }
  @media (max-width: 900px){

    display: unset;
  }
}


#mobile-nav-menu{
  display: none;
  position: absolute;
  background-color: rgba(243, 243, 243, 0.97);
  height: 100vh;
    left: 0;
  right: 0;
  top: 3.2rem;

  li.submenu{
    &::after{
      content: "";
    }

  }

  .level_2{
    display: none;
    position: absolute;
    &::after{
      content: "";
    }

  }
  .level_2.display-content{
    display: contents;
  }
}
#mobile-nav-menu.display-content{
  display:unset ;
}