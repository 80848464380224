.no-display{
  display: none;
}
$primary: #878787;
$font-size-h1: 1.3rem;


h1, h2, h3, h4, h5, h6, p, li{
  font-family: "default" ;
}

h1, h2, h3, h4{
  font-weight: 400 ;
  margin-top: 1.5rem !important;
  --bs-heading-color: #878787;

  @media (max-width: 500px) {

  }
}

h1, h2, h3, h4 p {
  color: #878787 ;
}

.grey-font{
  color: #878787 ;
}

.bold-font{
  font-weight: 500 ;
}

a{
  --bs-link-color-rgb: #e71d72 ;
}

.center-align{
  text-align: center;
}

li{
  strong.active
    {

      color: #e71d72;
    }

}

.center-align{
  text-align: center;
}

li.submenu strong{
  font-weight: normal;
}