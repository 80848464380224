@charset "UTF-8";
.no-display {
  display: none;
}

h1, h2, h3, h4, h5, h6, p, li {
  font-family: "default";
}

h1, h2, h3, h4 {
  font-weight: 400;
  margin-top: 1.5rem !important;
  --bs-heading-color: #878787;
}
h1, h2, h3, h4 p {
  color: #878787;
}

.grey-font {
  color: #878787;
}

.bold-font {
  font-weight: 500;
}

a {
  --bs-link-color-rgb: #e71d72 ;
}

.center-align {
  text-align: center;
}

li strong.active {
  color: #e71d72;
}

.center-align {
  text-align: center;
}

li.submenu strong {
  font-weight: normal;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.header-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin: 1rem 0;
}
.header-wrapper .header-logo img {
  height: 2.5rem;
  width: auto;
}
.header-wrapper .header-menu {
  color: black;
  width: 50%;
  padding-top: 8px;
}
.header-wrapper .header-menu ul.level_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.header-wrapper .header-menu ul.level_1 li {
  list-style: none;
}
.header-wrapper .header-menu ul.level_1 li a {
  text-decoration: none;
}
@media (max-width: 900px) {
  .header-wrapper .header-menu {
    display: none;
  }
}

li.submenu ul.level_2 {
  background-color: #fafafa;
  padding: 0.7rem 0;
  display: none;
  gap: 0.6rem;
  flex-direction: column !important;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  position: absolute;
}
li.submenu ul.level_2 li {
  max-width: 18rem;
  padding: 0.2rem 0.5rem;
}
li.submenu ul.level_2 li a, li.submenu ul.level_2 li strong {
  font-size: 0.8rem;
}
li.submenu:hover ul.level_2 {
  display: flex;
}
li.submenu:hover ul.level_2 li:not(.active):hover {
  background-color: #e71d72;
}
li.submenu:hover ul.level_2 li:not(.active):hover a {
  color: white;
}
li.submenu::after {
  content: "▼";
  font-size: 0.8rem;
  margin-left: 0.1rem;
  color: inherit;
}

.footer-nav nav ul {
  display: flex;
  padding: 0;
  flex-direction: row;
  gap: 2rem;
}

.mobile-nav-wrapper {
  display: none;
}
.mobile-nav-wrapper .burger-button {
  position: relative;
}
.mobile-nav-wrapper .burger-button img {
  width: 2rem;
  height: auto;
}
@media (max-width: 900px) {
  .mobile-nav-wrapper {
    display: unset;
  }
}

#mobile-nav-menu {
  display: none;
  position: absolute;
  background-color: rgba(243, 243, 243, 0.97);
  height: 100vh;
  left: 0;
  right: 0;
  top: 3.2rem;
}
#mobile-nav-menu li.submenu::after {
  content: "";
}
#mobile-nav-menu .level_2 {
  display: none;
  position: absolute;
}
#mobile-nav-menu .level_2::after {
  content: "";
}
#mobile-nav-menu .level_2.display-content {
  display: contents;
}

#mobile-nav-menu.display-content {
  display: unset;
}

.video-wrapper {
  position: relative;
}
.video-wrapper video {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  object-fit: cover;
  z-index: 0;
}
.video-wrapper figure img {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
}
@media (max-width: 500px) {
  .video-wrapper figure img {
    object-fit: cover;
    object-position: 50% 10%;
    height: 30vh;
  }
}

.contain .video-wrapper figure img {
  object-fit: contain;
}

@font-face {
  font-family: "default";
  src: url("../../fonts/RobotoSlab-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "default";
  src: url("../../fonts/RobotoSlab-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "default";
  src: url("../../fonts/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "default";
  src: url("../../fonts/RobotoSlab-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "default";
  src: url("../../fonts/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "default";
  src: url("../../fonts/RobotoSlab-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
ul li {
  list-style-type: square;
}
ul li::marker {
  color: #e71d72;
}

.row-start-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0;
}
@media (max-width: 770px) {
  .row-start-wrapper {
    justify-content: flex-start;
  }
}

.footer-block {
  background-color: #999;
  color: white;
  padding: 2rem 0;
  margin-top: 1rem;
}
.footer-block ul li {
  list-style: none;
}
.footer-block a {
  color: white;
}
.footer-block a:active {
  color: #e71d72;
}

main {
  min-height: 90vh;
}

.widget-text, .widget-textarea {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: center;
}
.widget-text label, .widget-textarea label {
  width: 20%;
}
.widget-text input, .widget-textarea input {
  width: 70%;
  border-radius: 15px;
  border: black 1px solid;
  padding: 0 1rem;
}

.widget-submit {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.widget-submit button {
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: white;
  background-color: black;
  border: black;
}
.widget-submit button:hover {
  cursor: pointer;
  background-color: #999;
  color: black;
  border: #999;
}

ul li a {
  text-decoration: none;
}