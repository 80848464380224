@font-face {
  font-family: 'default';
  src: url('../../fonts/RobotoSlab-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'default';
  src: url('../../fonts/RobotoSlab-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'default';
  src: url('../../fonts/RobotoSlab-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'default';
  src: url('../../fonts/RobotoSlab-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'default';
  src: url('../../fonts/RobotoSlab-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'default';
  src: url('../../fonts/RobotoSlab-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

