.video-wrapper{
  position: relative;

  video{
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    object-fit: cover;
    z-index: 0;
  }
  figure{
    img{
      width: 100vw;
      height: 50vh;
      object-fit: cover;
      @media (max-width: 500px) {
      object-fit: cover;
        object-position: 50% 10%;
        height: 30vh;
      }
    }
  }

}
.contain .video-wrapper figure img{
  object-fit: contain;
}